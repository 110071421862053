import React from 'react';

const Footer = () => {
  return (
    <div className="thehawkans-footer">
      <p>Contact us if you want your party not to suck</p>
    </div>
  )
}

export default Footer;
